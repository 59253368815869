
import { defineComponent, ref } from "vue";
import HorizontalButton from "@/components/HorizontalButton.vue";
import Item from "@/models/inventory/Item";
import { i18nOrderMessages, Order, OrderItem } from "@/models/sales/Order";
import PaymentInput from "./components/PaymentInput.vue";
import { useHTTP } from "@/plugins/http";
import { useAlertModal } from "@/plugins/alert-modal/alert-modal";
import { useI18n } from "vue-i18n";
import { localizeFieldName } from "@/plugins/i18n";
import { Register, RegisterLog } from "@/models/company/Register";
import { useContext } from "@/plugins/context";
import { useRouter } from "vue-router";
import AlertBadge from "@/components/AlertBadge.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import Customer from "@/models/customer/Customer";
import Dinero, { Currency } from "dinero.js";
import { formatDinero } from "@/utils/money";
import { Route } from "@/router/routes";
import User from "@/models/user/User";
import Location from "@/models/company/Location";

export default defineComponent({
  name: "new-checkout",
  components: {
    HorizontalButton,
    PaymentInput,
    AlertBadge,
    LoadingSpinner
  },
  async mounted() {
    // load register status
    try {
      this.loading = true;
      const registerLog = await this.http.get<RegisterLog>(
        `${Register.ENDPOINT}/status/${this.ctx.currentRegister.id}`
      );

      this.ctx.updateRegisterStatus(RegisterLog.from(registerLog).action);

      if (!registerLog.action || registerLog.action === "close") {
        await this.router.push(this.$Route.SALES_REGISTERS_OPEN_REGISTER);
      }
    } catch (error) {
      this.error.title = error.title;
      this.error.body = error.body;
    } finally {
      this.loading = false;
    }
  },

  watch: {
    "order.totalDiscountAmount": {
      handler: function(value: number) {
        this.order.totalDiscountDinero = Dinero({
          amount: value,
          currency: this.order.currency,
          precision: this.order.precision
        });
      }
    }
  },

  setup() {
    const http = useHTTP();
    const alertModal = useAlertModal();
    const ctx = useContext();
    const router = useRouter();
    const selectedIndex = ref(0);
    const itemSearchTerm = ref("");
    const order = ref(new Order());
    const paymentType = ref("");
    const checkoutDrawerOpen = ref(false);
    const quoteDrawerOpen = ref(false);
    const pressedEnter = ref(false);
    const data = ref(order.value.orderItems);
    const error = ref({ title: "", body: "" });
    const loading = ref(false);
    const customerDialogOpen = ref(false);
    const customers = ref(new Array<Customer>());
    const customerName = ref("");
    const direction = ref("rtl");
    const currentOrderItem = ref(new OrderItem(new Item()));
    const insertSerialDialogVisible = ref(false);
    const discountDialogOpen = ref(false);
    const { t } = useI18n({
      useScope: "global",
      messages: {
        en: {
          ...i18nOrderMessages.en,
          totalItems: "Total Items",
          totalDiscount: "Total Discount",
          subTotal: "Sub Total",
          totalDue: "Total Due",
          searchItems: "Enter item barcode or SKU or name",
          delete: "Delete",
          noCustomer: "No Customer Selected",
          amount: "Amount",
          percentage: "Percentage",
          buttons: {
            addCustomer: "Add Customer",
            payment: "Payment",
            direct: "Direct",
            creditSale: "Credit Sale",
            quote: "Save as Quote",
            cancel: "Cancel Order",
            cancelDiscount: "Cancel Discount",
            confirm: "Confirm",
            completePay: "Process Payment",
            checkout: "Checkout",
            printQuote: "Print Quote",
            checkoutQuote: "Checkout Quote Items",
            newTransaction: "New Transaction"
          },

          selectSerials: "Select serials",
          selectCustomer: "Select a customer",
          selectPaymentType: "Select order type",

          outOfStockErr:
            "Item {name} is out of stock. Please update your stock before selling this item",

          successMsg: "Sale complete",

          errPaymentMustMatch:
            "Payment must be made in full. If you'd like to pay partially, choose credit sale"
        },
        ar: {
          ...i18nOrderMessages.ar,
          totalItems: "إجمالي العناصر",
          totalDiscount: "اجمالي التخفيض",
          subTotal: "المبلغ الإجمالي",
          totalDue: "الاجمالي المستحق",
          searchItems: "ادخل الباركود او ال SKU او اسم المنتج",
          delete: "حذف",
          noCustomer: "لم يتم اختيار أي زبون",
          amount: "الخصم بالمبلغ",
          percentage: "الخصم بالنسبة",
          buttons: {
            addCustomer: "اضافة زبون",
            payment: "دفع",
            direct: "مباشر",
            creditSale: "بيع بالدين",
            quote: "الحفظ كعرض",
            cancel: "الغاء البيع",
            cancelDiscount: "الغاء التخفيض",
            confirm: "التأكيد",
            completePay: "اتمام الدفع",
            checkout: "الدفع",
            printQuote: "اطبع قائمة التسعير",
            checkoutQuote: "إتمام بيع قائمة التسعير",
            newTransaction: "عملية بيع جديدة"
          },

          selectSerials: "اختر الارقام",
          selectCustomer: "اختر زبون",
          selectPaymentType: "اختر نوع البيع",

          outOfStockErr:
            "نفذ مخزون المنتج {name}. يرجى اعادة تحديث المخزن قبل بيع المنتج.",

          successMsg: "تم اكمال البيع",

          errPaymentMustMatch:
            "يجب ان يتم دفع المبلغ بشكل كامل. لدفع مبلغ جزئي، قم بتحويل نوع الدفع الى دين او عربون"
        }
      }
    });
    const headers = [t("itemName"), t("price"), t("quantity"), t("total")];

    function openSerialDialog(oi: OrderItem) {
      currentOrderItem.value = oi;
      insertSerialDialogVisible.value = true;
    }

    function closeSerialDialog() {
      currentOrderItem.value = new OrderItem(new Item());
      insertSerialDialogVisible.value = false;
    }
    function addItem(selected: Item) {
      itemSearchTerm.value = "";
      // don't add items that are out of stock!
      if (
        selected.currentLocationStock.quantity <= 0 &&
        !(selected.unlimitedStock || selected.sellOutOfStock)
      ) {
        alertModal.showDanger({
          title: t("outOfStockErr", { name: selected.name })
        });
        return;
      }

      const oi = order.value.addItem(selected);
      if (selected.isSerialized) {
        openSerialDialog(oi);
      }
    }

    async function searchItems(query: string, cb: Function) {
      if (pressedEnter.value) {
        itemSearchTerm.value = "";
      }
      // if an item already exists in the table, don't do remote search
      const cachedItem =
        order.value.findByBarcode(query) || order.value.findBySKU(query);
      if (cachedItem) {
        addItem(cachedItem);
        cb([]);
        return;
      }
      try {
        const url = `${Item.ENDPOINT}?q=${query}`;
        let items = await http.get<Item[]>(url);
        items = items.map(item => Item.from(item));
        // when entering a barcode, the scanner hits enter by default
        // so if we get back one result, we wanna append that right away
        // it makes the process much faster than make the user select by themselves
        if (pressedEnter.value && items.length === 1) {
          addItem(items[0]);
          cb([]);
        } else {
          // show them all options
          cb(items);
        }
      } catch (error) {
        alertModal.showDanger({ title: error.title });
        cb([]);
      }
      pressedEnter.value = false;
    }

    function isOrderValid() {
      const errors: string[] = [];
      if (
        order.value.customerID === "" &&
        order.value.paymentType !== "direct"
      ) {
        errors.push(t("noCustomer"));
      }
      order.value.orderItems.forEach(oi => {
        if (oi.item?.isSerialized && oi.serialIDs.length !== oi.quantity) {
          const errMsg = `${oi.item.name}: ${t("validation.matchingLength", {
            thisField: localizeFieldName("lenSerialNumbers", i18nOrderMessages),
            thatField: localizeFieldName("quantity", i18nOrderMessages)
          })}`;
          errors.push(errMsg);
        }
      });

      if (errors.length) {
        error.value.title = t("validation.inputErrors");
        error.value.body = errors.join("\n");
        checkoutDrawerOpen.value = false;

        return false;
      }

      return true;
    }

    function openCheckoutDialog() {
      // order.value.prepareForCheckout();
      if (!isOrderValid()) {
        checkoutDrawerOpen.value = false;
      }
    }

    function openQuoteDialog() {
      quoteDrawerOpen.value = true;
    }

    function openCustomerDialog() {
      ctx.getDir() === "rtl"
        ? (direction.value = "rtl")
        : (direction.value = "ltr");

      customerDialogOpen.value = true;
    }

    function closeCustomerDialog() {
      customerName.value = selectedIndex.value
        ? customers.value[selectedIndex.value].contactDisplayName
        : "";
      customerDialogOpen.value = false;
    }

    function closeDiscountDialog() {
      discountDialogOpen.value = false;
    }

    function cancelDiscount() {
      discountDialogOpen.value = false;
      order.value.discountByAmount = 0;
      order.value.discountByPercentage = 0;
    }

    function openDiscountDialog() {
      discountDialogOpen.value = true;
    }

    async function searchCustomers(query: string) {
      try {
        const url = `${Customer.ENDPOINT}?q=${query}`;
        const customersRes = await http.get<Customer[]>(url);
        customers.value = customersRes.map(customer => Customer.from(customer));
      } catch (error) {
        alertModal.showDanger({ title: error.title });
        customers.value = [];
      }
    }

    function onCustomerNameChange(value: string) {
      selectedIndex.value = customers.value.findIndex(
        customer => customer.id === value
      );
    }

    function getDineroValue(
      amount: number,
      currency: Currency,
      precision: number
    ) {
      const dinero = Dinero({
        amount,
        currency,
        precision
      });

      return formatDinero(dinero);
    }

    function getItemTotal(orderItem: OrderItem) {
      const total =
        (orderItem.quantity as number) *
        (orderItem.item.salePriceAmount ? orderItem.item.salePriceAmount : 1);
      return getDineroValue(total, orderItem.currency, orderItem.precision);
    }

    function getTotalItemsCount() {
      const totalCount = order.value.orderItems.reduce((acc, item) => {
        return acc + item.quantity;
      }, 0);

      return totalCount;
    }

    function resetOrder() {
      order.value = new Order();
      // order.value.empty();
      pressedEnter.value = false;
      itemSearchTerm.value = "";
      error.value = { title: "", body: "" };

      currentOrderItem.value = new OrderItem(new Item());
      insertSerialDialogVisible.value = false;

      customerDialogOpen.value = false;
      customers.value = new Array<Customer>();

      checkoutDrawerOpen.value = false;
      quoteDrawerOpen.value = false;
    }

    function isValidPayment() {
      if (
        order.value.paymentType === "direct" &&
        order.value.totalDueAmount > order.value.paidBeforeChangeAmount
      ) {
        error.value.title = t("errPaymentMustMatch");
        checkoutDrawerOpen.value = false;

        return false;
      }

      return true;
    }

    async function submitOrder() {
      const requestData = order.value.preparePayload(
        ctx.currentUser as User,
        ctx.currentRegister as Register,
        ctx.currentLocation as Location
      );

      if (!isOrderValid() || !isValidPayment()) {
        return;
      } else {
        error.value.title = "";
        error.value.body = "";

        try {
          const o = await http.post<Order>(Order.ENDPOINT, requestData);
          alertModal.showSuccess({ title: t("successMsg") });
          await router.push(
            Route.SALES_ORDERS_ORDER_RECEIPT.replace(":id", o.id || "")
          );
        } catch (err) {
          error.value.title = err.title;
          error.value.body = err.body;
          checkoutDrawerOpen.value = false;
        }
      }
    }

    return {
      ctx,
      pressedEnter,
      itemSearchTerm,
      selectedIndex,
      order,
      searchItems,
      addItem,
      headers,
      data,
      checkoutDrawerOpen,
      openCheckoutDialog,
      paymentType,
      http,
      alertModal,
      t,
      error,
      loading,
      router,
      customerDialogOpen,
      customers,
      customerName,
      openCustomerDialog,
      closeCustomerDialog,
      onCustomerNameChange,
      searchCustomers,
      direction,
      currentOrderItem,
      insertSerialDialogVisible,
      closeSerialDialog,
      openSerialDialog,
      getDineroValue,
      getItemTotal,
      discountDialogOpen,
      closeDiscountDialog,
      openDiscountDialog,
      getTotalItemsCount,
      formatDinero,
      resetOrder,
      submitOrder,
      quoteDrawerOpen,
      openQuoteDialog,
      cancelDiscount
    };
  }
});
