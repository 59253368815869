<template>
  <div class="h-full">
    <div v-if="loading" class="flex items-center justify-center h-full">
      <loading-spinner diameter="64px" />
    </div>

    <alert-badge
      v-else-if="error.title"
      isDanger
      :title="error.title"
      :body="error.body"
      @dismissed="
        () => {
          error.title = '';
          error.body = '';
        }
      "
    />

    <div class="flex bg-white mx-4" style="height:95vh">
      <div class="cart w-2/3 mt-16 px-6">
        <div class="header h-14 flex flex-1 justify-between">
          <div class="search-box h-full w-full">
            <el-autocomplete
              v-model="itemSearchTerm"
              :fetch-suggestions="searchItems"
              :placeholder="t('searchItems')"
              @select="addItem"
              class="w-full h-full"
              value-key="name"
              @keypress.enter.prevent="pressedEnter = true"
              :trigger-on-focus="false"
              prefix-icon="el-icon-search"
            />
          </div>
        </div>
        <div
          class="items-table w-full mt-8 overflow-y-scroll "
          :key="order.orderItems.length"
          v-if="order.orderItems.length !== 0"
        >
          <table class="w-full">
            <tr class="h-16">
              <th v-for="header in headers" :key="header">
                {{ header }}
              </th>
              <th>{{ t("delete") }}</th>
            </tr>
            <tr
              class="h-16"
              v-for="(orderItem, index) in order.orderItems"
              :key="orderItem.item.name"
            >
              <td class="text-center item-name">
                <div
                  class="flex w-full justify-between px-2 items-center text-center"
                >
                  {{ orderItem.item.name }}
                  <span
                    v-if="orderItem.item.isSerialized"
                    @click="openSerialDialog(orderItem)"
                  >
                    <img
                      src="../../assets/serials.png"
                      alt="serials icon"
                      width="24"
                    />
                  </span>
                </div>
              </td>
              <td
                class="text-center flex justify-around items-center item-price"
              >
                {{
                  getDineroValue(
                    orderItem.item.salePriceAmount
                      ? orderItem.item.salePriceAmount
                      : 0,
                    orderItem.currency,
                    orderItem.precision
                  )
                }}
              </td>
              <td class="text-center item-quantity">
                <el-input-number
                  style="width: auto"
                  v-model="orderItem.quantity"
                  :step="1"
                  size="mini"
                  :min="1"
                ></el-input-number>
              </td>
              <td class="text-center item-total">
                {{ getItemTotal(orderItem) }}
              </td>
              <td class="text-center item-delete">
                <span
                  class="text-red-600 cursor-pointer"
                  @click="order.removeItem(index)"
                >
                  <font-awesome-icon icon="trash" />
                </span>
              </td>
            </tr>
          </table>
        </div>
      </div>

      <div class="aside w-1/3 border-l-2 flex flex-col mt-16 px-8">
        <div class="customer-button w-3/5 self-end">
          <horizontal-button
            :title="t('buttons.addCustomer')"
            faIcon="plus"
            styles="border border-primary text-primary button-secondary"
            @click="openCustomerDialog"
            :rounded="false"
          />
        </div>

        <div class="order-info my-4 bg-white">
          <div class="cashier-info flex justify-between">
            <h1>{{ t("user") }}</h1>
            <h1>{{ ctx.currentUser.fullName }}</h1>
          </div>

          <el-divider />

          <div
            class="customer-info border-primary p-2 bg-light-blue flex justify-center items-center"
          >
            <h1 class="text-l text-primary">
              {{ customerName === "" ? t("noCustomer") : customerName }}
            </h1>
          </div>

          <el-divider />

          <div class="items-total flex justify-between">
            <h1>{{ t("totalItems") }}</h1>
            <h1 v-if="order.orderItems.length">{{ getTotalItemsCount() }}</h1>
          </div>

          <el-divider />

          <div class="total-info">
            <div class="sub-total flex justify-between mb-2">
              <h1 class="text-l">{{ t("subTotal") }}</h1>
              <h1 v-if="order.orderItems.length" class="text-l">
                {{ formatDinero(order.computeTotal()) }}
              </h1>
            </div>
            <div class="total-discount flex justify-between">
              <div class="flex item-center justify-between">
                <h1 class="mr-2 text-l">{{ t("totalDiscount") }}</h1>
                <span
                  v-if="order.orderItems.length"
                  @click="openDiscountDialog"
                >
                  <img src="../../assets/discountTag.svg" alt="discount icon" />
                </span>
              </div>
              <h1
                v-if="order.orderItems.length"
                class="text-red-400 text-l"
                :key="order.totalDiscountAmount"
              >
                - {{ formatDinero(order.totalDiscountDinero) }}
              </h1>
            </div>
          </div>

          <el-divider />

          <div class="total flex justify-between bg-gray-100 p-2 -mx-4">
            <h1 class="font-bold 2xl:text-3xl lg:text-2xl text-l">
              {{ t("total") }}
            </h1>
            <h1
              v-if="order.orderItems.length"
              class="font-bold 2xl:text-3xl lg:text-2xl text-l"
            >
              {{ formatDinero(order.computeTotalAfterDiscount()) }}
            </h1>
          </div>

          <div class="action-buttons">
            <!-- TODO: add support for printing quote -->
            <!--            <horizontal-button-->
            <!--              v-if="order.orderItems.length"-->
            <!--              class="my-4"-->
            <!--              :title="t('buttons.quote')"-->
            <!--              faIcon="save"-->
            <!--              styles="border border-primary text-l button-secondary"-->
            <!--              @click="openQuoteDialog"-->
            <!--              :rounded="false"-->
            <!--            />-->
            <horizontal-button
              v-if="order.orderItems.length"
              class="my-4"
              :title="t('buttons.cancel')"
              faIcon="cancel"
              styles="border border-red-500 h-12 text-l text-red-500 hover:bg-red-500 hover:text-white"
              @click="resetOrder()"
              :rounded="false"
            />
            <horizontal-button
              :title="t('buttons.checkout')"
              styles="button-primary 2xl:h-20 lg:h-16 h-12 2xl:text-3xl lg:text-2xl text-l font-bold mt-4"
              :rounded="false"
              @click="
                () => {
                  if (order.orderItems.length) {
                    checkoutDrawerOpen = true;
                  }
                }
              "
            />
          </div>
          <el-drawer
            v-model="checkoutDrawerOpen"
            :direction="'rtl'"
            @open="openCheckoutDialog"
            :lock-scroll="false"
            class="overflow-scroll"
          >
            <div class="px-5">
              <div class="mb-2 w-full">
                <!-- I chose to keep the below as a comment just in case we decided to use labels later on again -->
                <!-- <p class="mb-2 font-semibold">{{ t("selectPaymentType") }}</p> -->
                <el-radio-group class="w-full flex" v-model="order.paymentType">
                  <el-radio-button
                    class="flex-1 text-2xl"
                    label="direct"
                    border
                  >
                    {{ t("buttons.direct") }}</el-radio-button
                  >
                  <el-radio-button
                    class="flex-1 text-2xl"
                    label="credit_sale"
                    border
                  >
                    {{ t("buttons.creditSale") }}</el-radio-button
                  >
                </el-radio-group>
              </div>

              <div class="flex justify-between items-center mt-8">
                <h1
                  class="w-auto flex items-center 2xl:text-l lg:text-l text-m"
                >
                  {{ t("totalDue") }}
                </h1>
                <div class="line border w-1/3 mx-2"></div>
                <h1
                  class="text-m w-auto flex items-center justify-end 2xl:text-l lg:text-l text-m text-red-500"
                >
                  {{ formatDinero(order.computeTotalAfterDiscount()) }}
                </h1>
              </div>

              <PaymentInput
                :totalDue="order.totalDueAmount"
                v-model:changeDue="order.changeDueAmount"
                v-model:paidBeforeChange="order.paidBeforeChangeAmount"
                v-model:totalPaid="order.totalPaidAmount"
                :currency="order.currency"
                :precision="order.precision"
              />

              <div class="flex items-center justify-between mb-2">
                <horizontal-button
                  class="text-lg complete-payment"
                  :title="t('buttons.completePay')"
                  @click="submitOrder()"
                  :rounded="false"
                />
              </div>
            </div>
          </el-drawer>
          <el-drawer
            v-model="quoteDrawerOpen"
            :direction="'rtl'"
            @open="openQuoteDialog"
            :lock-scroll="false"
            class="overflow-scroll"
          >
            <div class="p-5 h-full">
              <div class="flex p-6 justify-between bg-gray-300">
                <h1 class="text-l">quote #</h1>
                <h1 class="text-l">001929</h1>
              </div>
              <div class="flex justify-center items-center flex-col h-3/6">
                <img src="../../assets/quoteDone.svg" alt="quote done " />
                <h1 class="mt-4 text-2xl">quote have been saved</h1>
              </div>
              <div class="h-2/6 flex flex-col justify-between">
                <horizontal-button
                  styles="h-20 border-primary text-primary text-2xl"
                  :title="t('buttons.printQuote')"
                  @click="() => {}"
                  :rounded="false"
                />
                <horizontal-button
                  styles="h-20 border-primary text-primary text-2xl font-bold"
                  :title="t('buttons.checkoutQuote')"
                  @click="() => {}"
                  :rounded="false"
                />
                <horizontal-button
                  class="mt-4"
                  styles="h-20 bg-primary text-white text-2xl font-bold"
                  :title="t('buttons.newTransaction')"
                  @click="resetOrder"
                  :rounded="false"
                />
              </div>
            </div>
          </el-drawer>
          <el-dialog
            :title="t('selectCustomer')"
            v-model="customerDialogOpen"
            width="30%"
            @close="closeCustomerDialog"
          >
            <div class="flex items-center justify-center" :dir="$ctx.getDir()">
              <el-select
                filterable
                clearable
                class="w-full"
                v-model="order.customerID"
                value-key="contactDisplayName"
                remote
                @change="onCustomerNameChange"
                :remote-method="searchCustomers"
                :key="order.customerID"
              >
                <el-option
                  v-for="option in customers"
                  :key="option.id"
                  :label="option.contactDisplayName"
                  :value="option.id"
                ></el-option>
              </el-select>
            </div>
            <template #footer>
              <span class="dialog-footer">
                <el-button type="primary" @click="closeCustomerDialog">{{
                  t("buttons.confirm")
                }}</el-button>
              </span>
            </template>
          </el-dialog>
          <el-dialog
            :title="t('selectSerials')"
            v-model="insertSerialDialogVisible"
            width="30%"
            @close="closeSerialDialog"
          >
            <div class="flex items-center justify-center" :dir="$ctx.getDir()">
              <el-select
                v-if="currentOrderItem.item.id"
                filterable
                clearable
                multiple
                class="w-full"
                v-model="currentOrderItem.serialIDs"
                :multiple-limit="currentOrderItem.quantity"
              >
                <el-option
                  v-for="(item, i) in currentOrderItem.item.itemStocks[0]
                    .itemSerials"
                  :key="i"
                  :label="item.number"
                  :value="item.id"
                  ref="serialInput"
                >
                  <span :class="{ 'float-right': $ctx.getDir() == 'rtl' }">
                    {{ item.number }}
                  </span>
                </el-option>
              </el-select>
            </div>
            <template #footer>
              <span class="dialog-footer">
                <el-button type="primary" @click="closeSerialDialog">{{
                  t("buttons.confirm")
                }}</el-button>
              </span>
            </template>
          </el-dialog>
          <el-dialog
            v-model="discountDialogOpen"
            width="30%"
            @close="closeDiscountDialog"
          >
            <div
              class="w-full flex flex-col items-center justify-center"
              :dir="$ctx.getDir()"
            >
              <div class="w-full flex items-center justify-between">
                <h1 class="text-l font-bold">{{ t("subTotal") }}</h1>
                <h1 class="text-l font-bold">
                  {{
                    getDineroValue(
                      order.totalAmount,
                      order.currency,
                      order.precision
                    )
                  }}
                </h1>
              </div>

              <el-divider />

              <el-radio-group
                class="w-full flex mb-4"
                v-model="order.discountType"
              >
                <el-radio-button class="flex-1" label="percentage" border>
                  {{ t("percentage") }}</el-radio-button
                >
                <el-radio-button class="flex-1" label="amount" border>
                  {{ t("amount") }}</el-radio-button
                >
              </el-radio-group>

              <div
                v-if="order.discountType === 'percentage'"
                class="mb-4 w-full flex items-center justify-between"
              >
                <h1 class="text-l font-bold">{{ t("percentage") }}</h1>
                <div class="flex items-center">
                  <span class="mx-6 text-l font-bold">%</span>
                  <el-input-number
                    v-model="order.discountByPercentage"
                    :step="1"
                    size="medium"
                    :min="0"
                    :controls="false"
                  />
                </div>
              </div>
              <div
                v-if="order.discountType === 'amount'"
                class="mb-6 w-full flex items-center justify-between"
              >
                <h1 class="text-l font-bold">{{ t("amount") }}</h1>
                <div class="flex items-center">
                  <span class="mx-6 text-l font-bold">{{ $ctx.currency }}</span>
                  <el-input-number
                    v-model="order.discountByAmount"
                    size="medium"
                    :controls="false"
                  />
                </div>
              </div>

              <div
                class="w-full flex items-center justify-between bg-gray-200 p-4 "
              >
                <h1 class="text-l font-bold">{{ t("total") }}</h1>
                <h1 class="text-blue-500 text-l font-bold">
                  {{
                    getDineroValue(
                      order.totalAmount,
                      order.currency,
                      order.precision
                    )
                  }}
                </h1>
              </div>
            </div>
            <template #footer>
              <span class="dialog-footer">
                <el-button
                  type="primary"
                  class="bg-primary"
                  @click="closeDiscountDialog"
                  >{{ t("buttons.confirm") }}</el-button
                >
                <el-button type="danger" @click="cancelDiscount">{{
                  t("buttons.cancelDiscount")
                }}</el-button>
              </span>
            </template>
          </el-dialog>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import HorizontalButton from "@/components/HorizontalButton.vue";
import Item from "@/models/inventory/Item";
import { i18nOrderMessages, Order, OrderItem } from "@/models/sales/Order";
import PaymentInput from "./components/PaymentInput.vue";
import { useHTTP } from "@/plugins/http";
import { useAlertModal } from "@/plugins/alert-modal/alert-modal";
import { useI18n } from "vue-i18n";
import { localizeFieldName } from "@/plugins/i18n";
import { Register, RegisterLog } from "@/models/company/Register";
import { useContext } from "@/plugins/context";
import { useRouter } from "vue-router";
import AlertBadge from "@/components/AlertBadge.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import Customer from "@/models/customer/Customer";
import Dinero, { Currency } from "dinero.js";
import { formatDinero } from "@/utils/money";
import { Route } from "@/router/routes";
import User from "@/models/user/User";
import Location from "@/models/company/Location";

export default defineComponent({
  name: "new-checkout",
  components: {
    HorizontalButton,
    PaymentInput,
    AlertBadge,
    LoadingSpinner
  },
  async mounted() {
    // load register status
    try {
      this.loading = true;
      const registerLog = await this.http.get<RegisterLog>(
        `${Register.ENDPOINT}/status/${this.ctx.currentRegister.id}`
      );

      this.ctx.updateRegisterStatus(RegisterLog.from(registerLog).action);

      if (!registerLog.action || registerLog.action === "close") {
        await this.router.push(this.$Route.SALES_REGISTERS_OPEN_REGISTER);
      }
    } catch (error) {
      this.error.title = error.title;
      this.error.body = error.body;
    } finally {
      this.loading = false;
    }
  },

  watch: {
    "order.totalDiscountAmount": {
      handler: function(value: number) {
        this.order.totalDiscountDinero = Dinero({
          amount: value,
          currency: this.order.currency,
          precision: this.order.precision
        });
      }
    }
  },

  setup() {
    const http = useHTTP();
    const alertModal = useAlertModal();
    const ctx = useContext();
    const router = useRouter();
    const selectedIndex = ref(0);
    const itemSearchTerm = ref("");
    const order = ref(new Order());
    const paymentType = ref("");
    const checkoutDrawerOpen = ref(false);
    const quoteDrawerOpen = ref(false);
    const pressedEnter = ref(false);
    const data = ref(order.value.orderItems);
    const error = ref({ title: "", body: "" });
    const loading = ref(false);
    const customerDialogOpen = ref(false);
    const customers = ref(new Array<Customer>());
    const customerName = ref("");
    const direction = ref("rtl");
    const currentOrderItem = ref(new OrderItem(new Item()));
    const insertSerialDialogVisible = ref(false);
    const discountDialogOpen = ref(false);
    const { t } = useI18n({
      useScope: "global",
      messages: {
        en: {
          ...i18nOrderMessages.en,
          totalItems: "Total Items",
          totalDiscount: "Total Discount",
          subTotal: "Sub Total",
          totalDue: "Total Due",
          searchItems: "Enter item barcode or SKU or name",
          delete: "Delete",
          noCustomer: "No Customer Selected",
          amount: "Amount",
          percentage: "Percentage",
          buttons: {
            addCustomer: "Add Customer",
            payment: "Payment",
            direct: "Direct",
            creditSale: "Credit Sale",
            quote: "Save as Quote",
            cancel: "Cancel Order",
            cancelDiscount: "Cancel Discount",
            confirm: "Confirm",
            completePay: "Process Payment",
            checkout: "Checkout",
            printQuote: "Print Quote",
            checkoutQuote: "Checkout Quote Items",
            newTransaction: "New Transaction"
          },

          selectSerials: "Select serials",
          selectCustomer: "Select a customer",
          selectPaymentType: "Select order type",

          outOfStockErr:
            "Item {name} is out of stock. Please update your stock before selling this item",

          successMsg: "Sale complete",

          errPaymentMustMatch:
            "Payment must be made in full. If you'd like to pay partially, choose credit sale"
        },
        ar: {
          ...i18nOrderMessages.ar,
          totalItems: "إجمالي العناصر",
          totalDiscount: "اجمالي التخفيض",
          subTotal: "المبلغ الإجمالي",
          totalDue: "الاجمالي المستحق",
          searchItems: "ادخل الباركود او ال SKU او اسم المنتج",
          delete: "حذف",
          noCustomer: "لم يتم اختيار أي زبون",
          amount: "الخصم بالمبلغ",
          percentage: "الخصم بالنسبة",
          buttons: {
            addCustomer: "اضافة زبون",
            payment: "دفع",
            direct: "مباشر",
            creditSale: "بيع بالدين",
            quote: "الحفظ كعرض",
            cancel: "الغاء البيع",
            cancelDiscount: "الغاء التخفيض",
            confirm: "التأكيد",
            completePay: "اتمام الدفع",
            checkout: "الدفع",
            printQuote: "اطبع قائمة التسعير",
            checkoutQuote: "إتمام بيع قائمة التسعير",
            newTransaction: "عملية بيع جديدة"
          },

          selectSerials: "اختر الارقام",
          selectCustomer: "اختر زبون",
          selectPaymentType: "اختر نوع البيع",

          outOfStockErr:
            "نفذ مخزون المنتج {name}. يرجى اعادة تحديث المخزن قبل بيع المنتج.",

          successMsg: "تم اكمال البيع",

          errPaymentMustMatch:
            "يجب ان يتم دفع المبلغ بشكل كامل. لدفع مبلغ جزئي، قم بتحويل نوع الدفع الى دين او عربون"
        }
      }
    });
    const headers = [t("itemName"), t("price"), t("quantity"), t("total")];

    function openSerialDialog(oi: OrderItem) {
      currentOrderItem.value = oi;
      insertSerialDialogVisible.value = true;
    }

    function closeSerialDialog() {
      currentOrderItem.value = new OrderItem(new Item());
      insertSerialDialogVisible.value = false;
    }
    function addItem(selected: Item) {
      itemSearchTerm.value = "";
      // don't add items that are out of stock!
      if (
        selected.currentLocationStock.quantity <= 0 &&
        !(selected.unlimitedStock || selected.sellOutOfStock)
      ) {
        alertModal.showDanger({
          title: t("outOfStockErr", { name: selected.name })
        });
        return;
      }

      const oi = order.value.addItem(selected);
      if (selected.isSerialized) {
        openSerialDialog(oi);
      }
    }

    async function searchItems(query: string, cb: Function) {
      if (pressedEnter.value) {
        itemSearchTerm.value = "";
      }
      // if an item already exists in the table, don't do remote search
      const cachedItem =
        order.value.findByBarcode(query) || order.value.findBySKU(query);
      if (cachedItem) {
        addItem(cachedItem);
        cb([]);
        return;
      }
      try {
        const url = `${Item.ENDPOINT}?q=${query}`;
        let items = await http.get<Item[]>(url);
        items = items.map(item => Item.from(item));
        // when entering a barcode, the scanner hits enter by default
        // so if we get back one result, we wanna append that right away
        // it makes the process much faster than make the user select by themselves
        if (pressedEnter.value && items.length === 1) {
          addItem(items[0]);
          cb([]);
        } else {
          // show them all options
          cb(items);
        }
      } catch (error) {
        alertModal.showDanger({ title: error.title });
        cb([]);
      }
      pressedEnter.value = false;
    }

    function isOrderValid() {
      const errors: string[] = [];
      if (
        order.value.customerID === "" &&
        order.value.paymentType !== "direct"
      ) {
        errors.push(t("noCustomer"));
      }
      order.value.orderItems.forEach(oi => {
        if (oi.item?.isSerialized && oi.serialIDs.length !== oi.quantity) {
          const errMsg = `${oi.item.name}: ${t("validation.matchingLength", {
            thisField: localizeFieldName("lenSerialNumbers", i18nOrderMessages),
            thatField: localizeFieldName("quantity", i18nOrderMessages)
          })}`;
          errors.push(errMsg);
        }
      });

      if (errors.length) {
        error.value.title = t("validation.inputErrors");
        error.value.body = errors.join("\n");
        checkoutDrawerOpen.value = false;

        return false;
      }

      return true;
    }

    function openCheckoutDialog() {
      // order.value.prepareForCheckout();
      if (!isOrderValid()) {
        checkoutDrawerOpen.value = false;
      }
    }

    function openQuoteDialog() {
      quoteDrawerOpen.value = true;
    }

    function openCustomerDialog() {
      ctx.getDir() === "rtl"
        ? (direction.value = "rtl")
        : (direction.value = "ltr");

      customerDialogOpen.value = true;
    }

    function closeCustomerDialog() {
      customerName.value = selectedIndex.value
        ? customers.value[selectedIndex.value].contactDisplayName
        : "";
      customerDialogOpen.value = false;
    }

    function closeDiscountDialog() {
      discountDialogOpen.value = false;
    }

    function cancelDiscount() {
      discountDialogOpen.value = false;
      order.value.discountByAmount = 0;
      order.value.discountByPercentage = 0;
    }

    function openDiscountDialog() {
      discountDialogOpen.value = true;
    }

    async function searchCustomers(query: string) {
      try {
        const url = `${Customer.ENDPOINT}?q=${query}`;
        const customersRes = await http.get<Customer[]>(url);
        customers.value = customersRes.map(customer => Customer.from(customer));
      } catch (error) {
        alertModal.showDanger({ title: error.title });
        customers.value = [];
      }
    }

    function onCustomerNameChange(value: string) {
      selectedIndex.value = customers.value.findIndex(
        customer => customer.id === value
      );
    }

    function getDineroValue(
      amount: number,
      currency: Currency,
      precision: number
    ) {
      const dinero = Dinero({
        amount,
        currency,
        precision
      });

      return formatDinero(dinero);
    }

    function getItemTotal(orderItem: OrderItem) {
      const total =
        (orderItem.quantity as number) *
        (orderItem.item.salePriceAmount ? orderItem.item.salePriceAmount : 1);
      return getDineroValue(total, orderItem.currency, orderItem.precision);
    }

    function getTotalItemsCount() {
      const totalCount = order.value.orderItems.reduce((acc, item) => {
        return acc + item.quantity;
      }, 0);

      return totalCount;
    }

    function resetOrder() {
      order.value = new Order();
      // order.value.empty();
      pressedEnter.value = false;
      itemSearchTerm.value = "";
      error.value = { title: "", body: "" };

      currentOrderItem.value = new OrderItem(new Item());
      insertSerialDialogVisible.value = false;

      customerDialogOpen.value = false;
      customers.value = new Array<Customer>();

      checkoutDrawerOpen.value = false;
      quoteDrawerOpen.value = false;
    }

    function isValidPayment() {
      if (
        order.value.paymentType === "direct" &&
        order.value.totalDueAmount > order.value.paidBeforeChangeAmount
      ) {
        error.value.title = t("errPaymentMustMatch");
        checkoutDrawerOpen.value = false;

        return false;
      }

      return true;
    }

    async function submitOrder() {
      const requestData = order.value.preparePayload(
        ctx.currentUser as User,
        ctx.currentRegister as Register,
        ctx.currentLocation as Location
      );

      if (!isOrderValid() || !isValidPayment()) {
        return;
      } else {
        error.value.title = "";
        error.value.body = "";

        try {
          const o = await http.post<Order>(Order.ENDPOINT, requestData);
          alertModal.showSuccess({ title: t("successMsg") });
          await router.push(
            Route.SALES_ORDERS_ORDER_RECEIPT.replace(":id", o.id || "")
          );
        } catch (err) {
          error.value.title = err.title;
          error.value.body = err.body;
          checkoutDrawerOpen.value = false;
        }
      }
    }

    return {
      ctx,
      pressedEnter,
      itemSearchTerm,
      selectedIndex,
      order,
      searchItems,
      addItem,
      headers,
      data,
      checkoutDrawerOpen,
      openCheckoutDialog,
      paymentType,
      http,
      alertModal,
      t,
      error,
      loading,
      router,
      customerDialogOpen,
      customers,
      customerName,
      openCustomerDialog,
      closeCustomerDialog,
      onCustomerNameChange,
      searchCustomers,
      direction,
      currentOrderItem,
      insertSerialDialogVisible,
      closeSerialDialog,
      openSerialDialog,
      getDineroValue,
      getItemTotal,
      discountDialogOpen,
      closeDiscountDialog,
      openDiscountDialog,
      getTotalItemsCount,
      formatDinero,
      resetOrder,
      submitOrder,
      quoteDrawerOpen,
      openQuoteDialog,
      cancelDiscount
    };
  }
});
</script>

<style>
@import url(../../assets/css/index.css);
.bg-primary {
  background-color: var(--primary-bg-color);
}

.border-primary {
  border: 1px solid var(--primary-bg-color);
}

.text-primary {
  color: var(--primary-bg-color);
}

.bg-light-blue {
  background-color: #f2f7ff;
}

.item-name {
  width: 50%;
}

.item-price {
  height: inherit;
  width: auto;
}

.item-quantity {
  width: 15%;
}

.item-total {
  width: 15%;
}

.item-delete {
  width: 5%;
}

.el-radio-button__inner {
  width: 100% !important;
  font-size: 1.2rem;
}

.items-table {
  height: 75vh;
}

.el-drawer__header {
  padding-top: 5px;
}
</style>
